export interface Vehicle {
  id: number
  title: string
  description: string
  bg: string
  accent: string
  disabled?: boolean
}

export const states: Array<Vehicle> = [
  {
    id: 0,
    title: 'Active',
    description: 'Vehicle available to rent',
    bg: 'bg-leaf',
    accent: 'text-pine-200',
  },
  {
    id: 4,
    title: 'Fuel Low',
    description: 'Vehicle with low battery',
    bg: 'bg-forest-300',
    accent: 'text-forest-100',
  },
  {
    id: 5,
    title: 'Greenhouse',
    description: 'Vehicle is in greenhouse',
    bg: 'bg-clay',
    accent: 'text-maple-100',
  },
  {
    id: 2,
    title: 'Move',
    description: 'Rebalance in progress',
    bg: 'bg-river',
    accent: 'text-lagoon-200',
  },
  {
    id: 8,
    title: 'Support & Community',
    description: 'For S&C purposes',
    bg: 'bg-acer',
    accent: 'text-acer-200',
  },
  {
    id: 12,
    title: 'Damage Collect',
    description: 'Vehicle needs to be collected',
    bg: 'bg-maple',
    accent: 'text-maple-200',
  },
  {
    id: 10,
    title: 'Damaged',
    description: 'Vehicle is damaged',
    bg: 'bg-maple',
    accent: 'text-maple-200',
    disabled: true,
  },
  {
    id: 6,
    title: 'Missing',
    description: 'Vehicle is missing or stolen',
    bg: 'bg-bark',
    accent: 'text-bark-200',
    disabled: true,
  },
  {
    id: 3,
    title: 'Unreachable',
    description: 'Vehicle is unreachable',
    bg: 'bg-maple',
    accent: 'text-maple-200',
    disabled: true,
  },
  {
    id: 14,
    title: 'Forest Guardian',
    description: 'Vehicle is used by Forest Guardian',
    bg: 'bg-river',
    accent: 'text-lagoon-200',
    disabled: true,
  },
]

export const reportProperties = {
  damage: {
    color: 'bg-maple',
    ring: 'ring-2 ring-maple-600',
    icon: 'tabler:bandage',
    description: 'Damage report',
  },
  repair: {
    color: 'bg-aspen',
    ring: 'ring-2 ring-acer',
    icon: 'tabler:tool',
    description: 'Repair report',
  },
  missing: {
    color: 'bg-river',
    ring: 'ring-2 ring-lagoon-600',
    icon: 'tabler:map-search',
    description: 'Missing vehicle report',
  },
}

export const actionProperties = {
  honk: {
    description: 'Honk!',
    icon: 'tabler:bell-ringing',
    color: 'bg-river',
  },
  'battery-compartment-lock': {
    description: 'Opened battery compartment',
    icon: 'tabler:battery-charging',
    color: 'bg-aspen',
  },
  'unlock-mobilize': {
    description: 'Unlocked vehicle',
    icon: 'tabler:lock-open',
    color: 'bg-pine',
  },
  'lock-immobilize': {
    description: 'Locked vehicle',
    icon: 'tabler:lock',
    color: 'bg-maple',
  },
  'change-state': {
    description: 'Vehicle state changed',
    icon: 'tabler:access-point',
    color: 'bg-forest',
  },
  'switch-lights-on': {
    description: 'Switched lights on',
    icon: 'tabler:bulb',
    color: 'bg-forest',
  },
  'switch-lights-off': {
    description: 'Switched lights off',
    icon: 'tabler:bulb-off',
    color: 'bg-forest',
  },
  'modify-max-speed': {
    description: 'Modified max speed',
    icon: 'material-symbols:speed-outline-rounded',
    color: 'bg-forest',
  },
  'pdi-complete': {
    description: 'PDI complete',
    icon: 'tabler:checklist',
    color: 'bg-leaf',
  },
}

export const swapProperties = {
  critical: {
    color: 'bg-leaf',
    ring: 'ring-2 ring-pine-800',
    icon: 'tabler:battery-4',
    description: 'Critical swap',
  },
  normal: {
    color: 'bg-aspen',
    ring: 'ring-2 ring-acer',
    icon: 'tabler:battery-3',
    description: 'Normal swap',
  },
  soft: {
    color: 'bg-river',
    ring: 'ring-2 ring-lagoon-600',
    icon: 'tabler:battery-2',
    description: 'Soft swap',
  },
  unnecessary: {
    color: 'bg-maple',
    ring: 'ring-2 ring-maple-600',
    icon: 'tabler:battery-1',
    description: 'Unnecessary swap',
  },
  bad: {
    color: 'bg-forest',
    ring: 'ring-2 ring-forest-600',
    icon: 'tabler:battery-off',
    description: 'Bad swap',
  },
  uncompleted: {
    color: 'bg-forest-400',
    ring: 'ring-2 ring-forest-600',
    icon: 'tabler:exclamation-mark',
    description: 'Uncompleted swap',
  },
}
export const jobProperties = {
  diagnose: {
    color: 'bg-maple',
    ring: 'ring-2 ring-maple-600',
    icon: 'tabler:tool',
    description: 'Diagnose job',
  },
  mechanic: {
    color: 'bg-aspen',
    ring: 'ring-2 ring-acer',
    icon: 'tabler:tool',
    description: 'Mechanic job',
  },
  tester: {
    color: 'bg-river',
    ring: 'ring-2 ring-lagoon-600',
    icon: 'tabler:tool',
    description: 'Tester job',
  },
}